import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import './Services.css';
import * as servicesUtils from './ServicesUtils';


class ServicesMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderBottomImages: false,
            responsive: {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 1,
                    partialVisibilityGutter: 0,
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 0,

                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 0
                }
            },
        }
    }

    componentDidMount() {
        this.setState({ renderBottomImages: true });
    }

    returnBottomImages() {
        return (
            <>
                <div className="left-background-color" id="customKitchens">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="left-service-text-color mobile-header-spacing">Custom Kitchens</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomKitchensServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"left-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomKitchensServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="right-background-color" id="customPorchCeilings">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="right-service-text-color mobile-header-spacing">Custom Porch Ceilings</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomPorchCeilingsServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"right-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomPorchCeilingsServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="left-background-color" id="customStairs">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="left-service-text-color mobile-header-spacing">Custom Stairs</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomStairsServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"left-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomStairsServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="right-background-color" id="bathroomVanities">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="right-service-text-color mobile-header-spacing">Bathroom Vanities</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getBathroomVanityServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"right-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getBathroomVanityServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="left-background-color" id="bookshelves">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="left-service-text-color mobile-header-spacing">Bookshelves</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomBookshelfServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"left-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomBookshelfServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="right-background-color" id="entertainmentCenters">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="right-service-text-color mobile-header-spacing">Entertainment Centers</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getEntertainmentCentersServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"right-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getEntertainmentCentersServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="left-background-color" id="officeAndStorage">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="left-service-text-color mobile-header-spacing">Office and Storage</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getOfficeAndStorageServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"left-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getOfficeAndStorageServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    render() {
        return (
            <div className="">
                <div className="left-background-color" id="customBars">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="left-service-text-color mobile-header-spacing">Custom Bars</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                autoPlay
                                // autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomBarsServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"left-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomBarsServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="right-background-color" id="customClosets">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="right-service-text-color mobile-header-spacing">Custom Closets</h1>
                        </Col>
                    </Row>
                    <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{ width: '50%' }}>
                            <Carousel
                                responsive={this.state.responsive}
                                arrows
                                //autoPlay
                                ////autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                slidesToSlide={1}
                                containerClass="container-class-custom"
                                itemClass="item-class-custom"
                            >
                                {servicesUtils.getCustomClosetServicesObject.pictures.map((item, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={item.default}
                                            alt={"missing"}
                                            style={{ width: '60%', height: '60%' }}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                            <p className={"right-service-text-color service-description-text-mobile"}>
                                {servicesUtils.getCustomClosetServicesObject.description}
                            </p>
                        </Col>
                    </Row>
                </div>
                {!!this.state.renderBottomImages && this.returnBottomImages()}

                
                {/* <div className="right-background-color" id="outdoorKitchens">
                    <Row className="align-items-center no-gutters">
                        <Col>
                            <h1 className="right-service-text-color mobile-header-spacing">Outdoor Kitchens</h1>
                        </Col>
                    </Row> */}
                {/* <Row className="align-items-center no-gutters">
                        <Col className="carousel" style={{width: '50%'}}>
                            <Carousel
                                    responsive={this.state.responsive}
                                    arrows
                                    //autoPlay
                                    ////autoPlaySpeed={5000}
                                    centerMode={false}
                                    focusOnSelect={false}
                                    slidesToSlide={1}
                                    containerClass="container-class-custom"
                                    itemClass="item-class-custom"
                                >
                                    {servicesUtils.getOutdoorKitchenServicesObject.pictures.map((item, index) => {
                                        return (
                                            <img
                                                key={index}
                                                src={item.default}
                                                alt={"missing"}
                                                style={{width: '60%', height: '60%'}}
                                            />
                                        )
                                    })}
                                </Carousel>
                            </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col>
                        <p className={"right-service-text-color service-description-text-mobile"}>
                            {servicesUtils.getOutdoorKitchenServicesObject.description}
                        </p>
                        </Col>
                    </Row> */}
                {/* </div> */}
            </div>
        )
    }
}

export default ServicesMobile;