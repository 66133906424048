import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import './Services.css';
import * as servicesUtils from './ServicesUtils';


//to make this page look best, the pictures need to have a 3024*4032 (w*h) ratio
//for the ones that have a ratio where the width is greated than the height (ex: 4032*3024), 
//i had to crop the pictures as best i could to fit the golden ratio

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderBottomImages: false,
            responsive: {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 1,
                    partialVisibilityGutter: 0,
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 0,

                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 0
                }
            },
        }
    }

    componentDidMount() {
        this.setState({ renderBottomImages: true });
    }

    returnBottomImages() {
        return (
            <>
                <Row className={"left-background-color"} id="customKitchens">
                    <Col className="align-self-center">
                        <h1 className="left-service-text-color">Custom Kitchens</h1>
                        <p className={"left-service-text-color service-description-text"}>
                            {servicesUtils.getCustomKitchensServicesObject.description}
                        </p>
                    </Col>
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"
                        >
                            {servicesUtils.getCustomKitchensServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    {/* </Row> */}

                </Row>

                <Row className={"right-background-color"} id="customPorchCeilings">
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getCustomPorchCeilingsServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    <Col className="align-self-center">
                        <h1 className="right-service-text-color">Custom Porch Ceilings</h1>
                        <p className={"right-service-text-color service-description-text"}>
                            {servicesUtils.getCustomPorchCeilingsServicesObject.description}
                        </p>
                    </Col>
                </Row>
                <Row className={"left-background-color"} id="customStairs">
                    {/* <Col className="align-self-center"> */}
                    <Col className="align-self-center">
                        <h1 className="left-service-text-color">Custom Stairs</h1>
                        <p className={"left-service-text-color service-description-text"}>
                            {servicesUtils.getCustomStairsServicesObject.description}
                        </p>
                    </Col>
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getCustomStairsServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                </Row>
                <Row className={"right-background-color"} id="bathroomVanities">
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getBathroomVanityServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    <Col className="align-self-center">
                        <h1 className="right-service-text-color">Bathroom Vanities</h1>
                        <p className={"right-service-text-color service-description-text"}>
                            {servicesUtils.getBathroomVanityServicesObject.description}
                        </p>
                    </Col>
                </Row>
                <Row className={"left-background-color"} id="bookshelves">
                    {/* <Col className="align-self-center"> */}
                    <Col className="align-self-center">
                        <h1 className="left-service-text-color">Bookshelves</h1>
                        <p className={"left-service-text-color service-description-text"}>
                            {servicesUtils.getCustomBookshelfServicesObject.description}
                        </p>
                    </Col>
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getCustomBookshelfServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                </Row>
                <Row className={"right-background-color"} id="entertainmentCenters">
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getEntertainmentCentersServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    <Col className="align-self-center">
                        <h1 className="right-service-text-color">Entertainment Centers</h1>
                        <p className={"right-service-text-color service-description-text"}>
                            {servicesUtils.getEntertainmentCentersServicesObject.description}
                        </p>
                    </Col>
                </Row>
                <Row className={"left-background-color"} id="officeAndStorage">
                    {/* <Col className="align-self-center"> */}
                    <Col className="align-self-center">
                        <h1 className="left-service-text-color">Office and Storage</h1>
                        <p className={"left-service-text-color service-description-text"}>
                            {servicesUtils.getOfficeAndStorageServicesObject.description}
                        </p>
                    </Col>
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getOfficeAndStorageServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                </Row>
            </>
        )
    }

    render() {
        return (
            <div>
                <Row className={"left-background-color"} id="customBars">
                    <Col className="align-self-center">
                        <h1 className="left-service-text-color">Custom Bars</h1>
                        <p className={"left-service-text-color service-description-text"}>
                            {servicesUtils.getCustomBarsServicesObject.description}
                        </p>
                    </Col>
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            autoPlay
                            // autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getCustomBarsServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                </Row>
                <Row className={"right-background-color"} id="customClosets">
                    <Col className="carousel" style={{ width: '50%' }}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getCustomClosetServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{ width: '60%', height: '60%' }}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    <Col className="align-self-center">
                        <h1 className="right-service-text-color">Custom Closets</h1>
                        <p className={"right-service-text-color service-description-text"}>
                            {servicesUtils.getCustomClosetServicesObject.description}
                        </p>
                    </Col>
                </Row>
                {!!this.state.renderBottomImages && this.returnBottomImages()}

                {/* <Row className={"right-background-color"} id="outdoorKitchens">
                    <Col className="carousel" style={{width: '50%'}}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            //autoPlay
                            ////autoPlaySpeed={5000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            containerClass="container-class-custom"
                            itemClass="item-class-custom"

                        >
                            {servicesUtils.getOutdoorKitchenServicesObject.pictures.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.default}
                                        alt={"missing"}
                                        style={{width: '60%', height: '60%'}}
                                    />
                                )
                            })}

                        </Carousel>
                    </Col>
                    <Col className="align-self-center">
                        <h1 className="right-service-text-color">Outdoor Kitchens</h1>
                        <p className={"right-service-text-color service-description-text"}>
                            {servicesUtils.getOutdoorKitchenServicesObject.description}
                        </p>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default Services;