import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';

import aboutUsImage from './aboutUsImage.jpg';
import './AboutUs.css';

class AboutUsMobile extends Component {
    render() {
        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col>
                        <h1 className={"about-us-text-color"}>About Us</h1>
                        <h2 className={"about-us-text-color"}>A Passion for Detail</h2>
                        <p className={"about-us-text-mobile about-us-text-color"}>I began my business in 2000 to give the community of Houston access to beautiful, handmade cabinetry and woodwork. What began as building something out of necessity, grew into a full-time company specializing in custom handcrafted goods. Artistry isn’t just the execution and production of new Abasta's Cabinets pieces, it’s also making sure each piece will look beautiful in your home. Contact me to learn more about how I can customize your cabinetry.</p>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <img
                        src={aboutUsImage}
                        alt="missing"
                        style={{width: '100%', height: '100%'}}
                    />
                </Row>
            </div>
        )
    }
}

export default AboutUsMobile;