import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';

import ContactUs from './ContactUs';
import ContactUsMobile from './ContactUsMobile';

function ContactUsResponsive(props) {

    return (
        <>
            <Hidden smDown>
                <ContactUs/>
            </Hidden>
            <Hidden mdUp>
                <ContactUsMobile                    
                />
            </Hidden>
        </>
    )
}

ContactUsResponsive.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(ContactUsResponsive);