import React from 'react';

import backdropImage from './backdropimage.jpg';
import './ParallaxImage.css'



function ParallaxImageMobile() {
    return (
        <div className="image-container">
            <div style={{ height: '5vh' }}></div>
            <img
                src={backdropImage}
                alt="missing"
                style={{width: '100%', height: '100%'}}
            />
            <div className="inner-text-mobile text-style-mobile">
                <span>
                <h1 className={"parallax-inner-header-mobile"}>
                    Abasta's Cabinets
                </h1>
                </span>
                <p className={"parallax-support-text-mobile"}>Custom handcrafted cabinetry and carpentry</p>
            </div>
            <div style={{ height: '5vh' }}></div>
        </div>
    )
}

export default ParallaxImageMobile;