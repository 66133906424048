export const getCustomBarsServicesObject =
{
    description: "Let us design and build you a custom bar that you can be proud of and can  " +
                "show off to your friends and family anytime you host.",
    pictures: getCustomBarImages()
}

function getCustomBarImages() {
    return importAllImages(require.context(
        './servicesImages/customBars', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getCustomClosetServicesObject =
{
    description: "Abasta's Cabinets' custom walk-in closets are modern and intelligently designed to meet your " +
                "organizational needs, all while showcasing a style unique to you.",
    pictures: getCustomClosetImages()
}

function getCustomClosetImages() {
    return importAllImages(require.context(
        './servicesImages/customClosets', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getCustomStairsServicesObject =
{
    description: "Our staircases are designed with the highest quality craftsmanship and will " + 
                "be a beautiful addition to your home. Your staircase will be an eyecatching focal point " +
                "that will wow and impress all your guests.",
    pictures: getCustomStairImages()
}

function getCustomStairImages() {
    return importAllImages(require.context(
        './servicesImages/customStairs', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getCustomKitchensServicesObject =
{
    description: "Our custom kitchen's overall designs and styles reflect each clients personality. " + 
                "Desigining kitchens can be complicated for some clients, but with our 20+ years of experience, we are able to help our clients design their " +
                "kitchen down to the most minute details.",
    pictures: getCustomKitchenImages()
}

function getCustomKitchenImages() {
    return importAllImages(require.context(
        './servicesImages/customKitchens', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getBathroomVanityServicesObject =
{
    description: "Abasta's bathroom vanities are one of our more popular services as they are built to complement and " +
                "enhance the overall feel and look of your bathroom.",
    pictures: getBathroomVanityImages()
}

function getBathroomVanityImages() {
    return importAllImages(require.context(
        './servicesImages/bathroomVanity', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getCustomBookshelfServicesObject =
{
    description: "Custom bookshelves can elegantly display your book collection. " +
                "It can also display many other items to help bring life into your home.",
    pictures: getCustomBookshelfImages()
}

function getCustomBookshelfImages() {
    return importAllImages(require.context(
        './servicesImages/bookshelves', false, /\.(png|jpe?g|svg)$/
    ));
}


export const getCustomPorchCeilingsServicesObject =
{
    description: "Most porches are usually open with joists or covered with some material. With our ceiling " +
                "bead board or tongue and groove material, your porch will create a warm inviting porch.",
    pictures: getCustomPorchCeilingImages()
}

function getCustomPorchCeilingImages() {
    return importAllImages(require.context(
        './servicesImages/customPorchCeilings', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getEntertainmentCentersServicesObject =
{
    description: "Entertainment centers are the center piece of anybody's home. We can offer any design and " + 
                "layout for all of your electronic components. We also keep all your electronics neat and organized " +
                "by hiding all the cables and giving each component its individual storage space.",
    pictures: getEntertainmentCenterImages()
}

function getEntertainmentCenterImages() {
    return importAllImages(require.context(
        './servicesImages/entertainmentCenters', false, /\.(png|jpe?g|svg)$/
    ));
}

export const getOfficeAndStorageServicesObject =
{
    description: "Home offices are usually used to run home-based businesses, work from home, or just simply where you " +
                "take care of important tasks. That being said, we believe that your home office should be a cozy " +
                "environment, customized to your liking.",
    pictures: getOfficeAndStorageImages()
}

function getOfficeAndStorageImages() {
    return importAllImages(require.context(
        './servicesImages/officeAndStorage', false, /\.(png|jpe?g|svg)$/
    ));
}

// export const getOutdoorKitchenServicesObject =
// {
//     description: "hi",
//     pictures: getOutdoorKitchenImages()
// }

// function getOutdoorKitchenImages() {
//     return importAllImages(require.context(
//         './servicesImages/outdoorKitchen', false, /\.(png|jpe?g|svg)$/
//     ));
// }



function importAllImages(r) {
    return r.keys().map(r);
}