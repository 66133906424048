import React, {Component} from 'react';

import ParallaxImageMobile from '../../components/home/parallaxImage/ParallaxImageMobile';
import AboutUsMobile from '../../components/home/aboutUs/AboutUsMobile';
import PortfolioMobile from '../../components/home/portfolio/PortfolioMobile';
class HomeMobile extends Component {

    render() {
        return (
            <div>
                <div className="parallax-image-background">
                    <div style={{
                        width: '90%',
                        margin: '0 auto',
                    }}>
                        <ParallaxImageMobile />
                    </div>
                </div>
                <div className="about-us-background">
                    <AboutUsMobile />
                </div>
                <div className="portfolio-image-background">
                    <PortfolioMobile/>
                </div>
            </div>
        )
    }
}

export default HomeMobile;