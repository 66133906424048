import React, {Component} from 'react';
import { Row, Col, FormControl, Form, Button } from 'react-bootstrap';

import { FaInstagram } from 'react-icons/fa';
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import ModalPopup from "../../components/common/ModalPopup";
import "./ContactUs.css"
import * as contactUsUtils from "./ContactUsUtils";
import staticMap from './staticMap500x700.png';
import { Axios } from "../../firebase/firebaseConfig";
import contactUsImage from './contactUsImage.JPG';




class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = contactUsUtils.getInitialState();
    }

    onPreferredContactMethodChosen = (e) => {
        this.setState({
            userEmail: '',
            userPhoneNumber: '',
            preferredContactMethod: e.target.value
        });
    }

    submitButtonClicked = () => {
        if(!contactUsUtils.allFieldsArePopulated(this.state)) {
            alert('Please fill out all the fields');
            return;
        }

        if(this.state.preferredContactMethod === 'email' && !contactUsUtils.emailIsValid(this.state.userEmail)) {
            alert('Invalid email');
            return;
        }
        
        this.sendEmail();
    }

    sendEmail = () => {
        let userContactInfo = this.state.preferredContactMethod === 'email' 
            ? this.state.userEmail
            : this.state.userPhoneNumber;

        
        Axios.post('https://us-central1-abastas-cabinets.cloudfunctions.net/submit', 
        {
            senderContactInfo: userContactInfo,
            subject: this.state.userEmailSubject,
            message: this.state.userEmailMessage,
        })
        .then((response) => {
            this.openSuccessModal();
            this.clearEmailTextBoxes();
        }, (error) => {
            console.log(error);
            this.openFailureModal();
        });
    }

    clearEmailTextBoxes = () => {
        this.setState({
            userEmail: "",
            userPhoneNumber: "",
            userEmailSubject: "",
            userEmailMessage: "",
        })
    }

    openSuccessModal = () => {
        this.setState({showSuccessModal: true});
    }

    closeSuccessModal = () => {
        this.setState({showSuccessModal: false});
    }

    openFailureModal = () => {
        this.setState({showFailureModal: true});
    }

    closeFailureModal = () => {
        this.setState({showFailureModal: false});
    }

    
    render() {
        return (
            <div className="container-fluid grey-background-color">
                <ModalPopup
                    showModal={this.state.showSuccessModal}
                    closeModal={() => this.closeSuccessModal()}
                    modalTitle={contactUsUtils.modalPopupSuccessTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailSuccessMessage()}
                />

                <ModalPopup //this is the Failure popup
                    showModal={this.state.showFailureModal}
                    closeModal={() => this.closeFailureModal()}
                    modalTitle={contactUsUtils.modalPopupFailureTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailFailureMessage()}
                />

                

                <Row>
                    <Col lg={"5"} md={"5"} sm={"5"} className="contact-us-section-text-align-left">
                        <div style={{ height: '5vh' }}></div>
                        <div className="right-border">
                            {/* <div style={{ height: '5vh' }}></div> */}

                            <Row className="contact-us-image">
                                <img 
                                    src={contactUsImage}
                                    className="contact-us-image-styling"
                                    alt="missing"
                                    style={{ width: '80%', height: '80%' }}
                                />
                            </Row>

                            <Row className="contact-info-headers">
                                <div>
                                    <div className="contact-info-label">Contact Info</div>
                                    {/* <p className="contact-info-abastas-cabinets-label"><strong>Abasta's Cabinets</strong></p> */}
                                    <div className="contact-info-abastas-cabinets-label">Abasta's Cabinets</div>
                                </div>
                            </Row>


                            <Row className="phone-numbers">
                                <div>
                                    <div className="contact-us-section-headers">Phone</div>
                                    <div className="padding-5-percent">
                                    <BsPhone className="phone"/>
                                        <a href="tel:832-310-8523" className="image-link-styling-and-spacing">
                                            {'English: 832-310-8523'}
                                        </a>
                                    </div>
                                    <div className="padding-5-percent">
                                        <BsPhone className="phone"/>
                                        <a href="tel:832-638-4865" className="image-link-styling-and-spacing">
                                            {'Spanish: 832-638-4865'}
                                        </a>
                                    </div>
                                </div>
                            </Row>


                            <Row className="email">
                                <div>
                                    <div className="contact-us-section-headers">Email</div>
                                    <div id="padding-5-percent">
                                        <AiOutlineMail className="phone"/>
                                        <a href="mailto: abastascustomcabinets@gmail.com" className="image-link-styling-and-spacing">
                                            {'abastascustomcabinets@gmail.com'}
                                        </a>
                                    </div>
                                </div>
                            </Row>


                            <Row className="socials">
                                <div>
                                    <div className="contact-us-section-headers">Socials</div>
                                    <div id="padding-5-percent">
                                        <FaInstagram className="instagram"/> 
                                        <a href="https://www.instagram.com/abastascabinets/" className="image-link-styling-and-spacing">
                                            @abastascabinets
                                        </a>
                                    </div>
                                    <div id="padding-5-percent">
                                        <AiOutlineFacebook className="facebook"/> 
                                        <a href="https://www.facebook.com/abastascabinets" className="image-link-styling-and-spacing">
                                            Abasta's Cabinets
                                        </a>
                                    </div>
                                </div>
                            </Row>

                            <Row className="houston">
                                <div>
                                    <div className="contact-us-section-headers">Houston, TX</div>
                                </div>
                            </Row>
                        </div>
                    </Col>



                    <Col lg={"3"} md={"3"} sm="3" className="send-a-message-section-text-align-left">
                        <div style={{ height: '5vh' }}></div>
                        <div>
                            <div style={{ height: '5vh' }}></div>
                            <Row className="send-a-message">
                                <div>
                                    <div className="send-a-message-label">Send Us A Message</div>
                                    <div className="contact-us-section-headers">Preferred Contact Method</div>
                                    <div className="radio-buttons">
                                        <Form>
                                            <Form.Group>
                                                <Form.Check
                                                    type="radio"
                                                    value="email"
                                                    label="Email"
                                                    onChange={this.onPreferredContactMethodChosen}
                                                    checked={this.state.preferredContactMethod === 'email'}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Phone"
                                                    value="phone"
                                                    onChange={e => this.onPreferredContactMethodChosen(e)}
                                                    checked={this.state.preferredContactMethod === 'phone'}

                                                />
                                                </Form.Group>
                                                {this.state.preferredContactMethod === 'email' &&
                                                    <Form.Group>
                                                        <FormControl
                                                            placeholder="Email"
                                                            type="email"
                                                            value={this.state.userEmail}
                                                            onChange={e => this.setState({userEmail: e.target.value})}
                                                        />
                                                    </Form.Group>
                                                }
                                                {this.state.preferredContactMethod === 'phone' &&
                                                    <Form.Group>
                                                        <FormControl
                                                            placeholder="Phone"
                                                            type="phone"
                                                            inputMode="numeric"
                                                            onChange={e => this.setState({userPhoneNumber: e.target.value})}
                                                        />
                                                    </Form.Group>
                                                }
                                                <Form.Group className="email-input">
                                                    <FormControl
                                                        className="email-input"
                                                        placeholder="Subject"
                                                        aria-label="Subject"
                                                        aria-describedby="basic-addon1"
                                                        value={this.state.userEmailSubject}
                                                        onChange={e => this.setState({userEmailSubject: e.target.value})}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <FormControl 
                                                        className="message-input" 
                                                        placeholder="Message"
                                                        as="textarea" 
                                                        rows={6}
                                                        value={this.state.userEmailMessage}
                                                        onChange={e => this.setState({userEmailMessage: e.target.value})}
                                                    />
                                                </Form.Group>
                                                <Button 
                                                    variant="primary"
                                                    size="lg"
                                                    className="button" 
                                                    onClick={this.submitButtonClicked}
                                                    >SUBMIT</Button>
                                            </Form>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Col>


                    <Col lg md sm="3">
                        <div>
                            <img
                            className="map-image-size"
                            src={staticMap}
                            alt={"missing"}
                            />
                        </div>
                    </Col>

                    
                </Row>
                {/* <div style={{ height: '20vh' }}></div> */}
            </div>
            
        )
    }
}

export default ContactUs;