import React, {Component} from 'react';
import { Row, FormControl, Form, Button, Col } from 'react-bootstrap';
import "./ContactUs.css"
import { FaInstagram } from 'react-icons/fa';
import { AiOutlineFacebook } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import staticMap from './staticMap500x700.png';
import * as contactUsUtils from "./ContactUsUtils";
import contactUsImage from './contactUsImage.JPG';
import { Axios } from "../../firebase/firebaseConfig";
import ModalPopup from "../../components/common/ModalPopup";


class ContactUsMobile extends Component {
    constructor(props) {
        super(props);
        this.state = contactUsUtils.getInitialState();
    }

    componentDidMount() {
        
    }

    onPreferredContactMethodChosen = (e) => {
        this.setState({
            userEmail: '',
            userPhoneNumber: '',
            preferredContactMethod: e.target.value
        });
    }

    submitButtonClicked = () => {
        if(!contactUsUtils.allFieldsArePopulated(this.state)) {
            alert("Please fill out all the fields");
            return;
        }

        if(this.state.preferredContactMethod === 'email' && !contactUsUtils.emailIsValid(this.state.userEmail)) {
            alert('Invalid email');
            return;
        }

        this.sendEmail();
    }

    sendEmail = () => {
        let userContactInfo = this.state.preferredContactMethod === 'email' 
            ? this.state.userEmail
            : this.state.userPhoneNumber;

        
        console.log(userContactInfo);
        Axios.post('https://us-central1-abastas-cabinets.cloudfunctions.net/submit', 
        {
            senderContactInfo: userContactInfo,
            subject: this.state.userEmailSubject,
            message: this.state.userEmailMessage,
        })
        .then((response) => {
            this.openSuccessModal();
            this.clearEmailTextBoxes();
        }, (error) => {
            console.log(error);
            this.openFailureModal();
        });
    }

    clearEmailTextBoxes = () => {
        this.setState({
            userEmail: "",
            userEmailSubject: "",
            userEmailMessage: "",
        })
    }

    openSuccessModal = () => {
        this.setState({showSuccessModal: true});
    }

    closeSuccessModal = () => {
        this.setState({showSuccessModal: false});
    }

    openFailureModal = () => {
        this.setState({showFailureModal: true});
    }

    closeFailureModal = () => {
        this.setState({showFailureModal: false});
    }

    render() {
        return (
            <div className="container-fluid grey-background-color">
                <ModalPopup
                    showModal={this.state.showSuccessModal}
                    closeModal={() => this.closeSuccessModal()}
                    modalTitle={contactUsUtils.modalPopupSuccessTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailSuccessMessage()}
                />
                <ModalPopup //this is the Failure popup
                    showModal={this.state.showFailureModal}
                    closeModal={() => this.closeFailureModal()}
                    modalTitle={contactUsUtils.modalPopupFailureTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailFailureMessage()}
                />

                <div style={{ height: '5vh' }}></div>
                <Row className="contact-us-image">
                    <img
                        src={contactUsImage}
                        alt="missing"
                        style={{ width: '100%', height: '100%' }}
                    />
                </Row>

                <Row className="contact-info-headers align-items-center">
                    <Col>
                        <div className="contact-info-label-mobile">Contact Info</div>
                        <div className="contact-info-abastas-cabinets-label">abastascustomcabinets@gmail.com</div>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col className="phone-numbers">
                        <div>
                            <div className="contact-us-section-headers-mobile">Phone</div>
                            <Row className="justify-content-center">
                                <BsPhone className="phone-mobile"/>
                                <a href="tel:832-310-8523" className="image-link-styling-and-spacing-mobile">
                                    {'English: 832-310-8523'}
                                </a>
                            </Row>
                            <Row className="justify-content-center">
                                <BsPhone className="phone-mobile"/>
                                <a href="tel:832-638-4865" className="image-link-styling-and-spacing-mobile">
                                    {'Spanish: 832-638-4865'}
                                </a>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <div className="contact-us-section-headers-mobile">Socials</div>
                        <Row className="justify-content-center">
                            <FaInstagram className="instagram-mobile"/> 
                            <a href="https://www.instagram.com/abastascabinets/" className="image-link-styling-and-spacing-mobile">
                                {"@abastascabinets"}
                            </a>
                        </Row>
                        <Row className="justify-content-center">
                            <AiOutlineFacebook className="facebook-mobile"/> 
                            <a href="https://www.facebook.com/abastascabinets" className="image-link-styling-and-spacing-mobile">
                                Abasta's Cabinets
                            </a>
                        </Row>
                    </Col>
                </Row>
                
                <Row className="send-a-message justify-content-center">
                    <div>
                        <div className="send-a-message-label">Send Us A Message</div>
                        <div className="contact-us-section-headers-mobile">Preferred Contact Method</div>
                        <div className="radio-buttons">
                            <Form>
                                <Form.Group>
                                    <Form.Check
                                        type="radio"
                                        inline
                                        value="email"
                                        label="Email"
                                        onChange={this.onPreferredContactMethodChosen}
                                        checked={this.state.preferredContactMethod === 'email'}
                                    />
                                    <Form.Check
                                        type="radio"
                                        inline
                                        label="Phone"
                                        value="phone"
                                        onChange={e => this.onPreferredContactMethodChosen(e)}
                                        checked={this.state.preferredContactMethod === 'phone'}

                                    />
                                    </Form.Group>
                                    {this.state.preferredContactMethod === 'email' &&
                                        <Form.Group>
                                            <FormControl
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.userEmail}
                                                onChange={e => this.setState({userEmail: e.target.value})}
                                            />
                                        </Form.Group>
                                    }
                                    {this.state.preferredContactMethod === 'phone' &&
                                        <Form.Group>
                                            <FormControl
                                                placeholder="Phone"
                                                type="phone-mobile"
                                                inputMode="numeric"
                                                onChange={e => this.setState({userPhoneNumber: e.target.value})}
                                            />
                                        </Form.Group>
                                    }
                                    <Form.Group className="email-input">
                                        <FormControl
                                            className="email-input"
                                            placeholder="Subject"
                                            aria-label="Subject"
                                            aria-describedby="basic-addon1"
                                            value={this.state.userEmailSubject}
                                            onChange={e => this.setState({userEmailSubject: e.target.value})}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <FormControl 
                                            className="message-input" 
                                            placeholder="Message"
                                            as="textarea" 
                                            rows={6}
                                            value={this.state.userEmailMessage}
                                            onChange={e => this.setState({userEmailMessage: e.target.value})}
                                        />
                                    </Form.Group>
                                    <Button 
                                        variant="primary"
                                        size="lg"
                                        className="button" 
                                        onClick={this.submitButtonClicked}
                                        >SUBMIT</Button>
                                </Form>
                        </div>
                    </div>
                </Row>
                <div style={{ height: '2vh' }}></div>
                <Row>
                    <img
                        src={staticMap}
                        style={{ width: '100%', height: '80%' }}
                        alt={"missing"}
                    />
                </Row>

                <div style={{ height: '2vh' }}></div>
                <Row className="justify-content-center">
                    <div className="bottom-abastas-cabinets-label">Abasta's Cabinets</div>
                </Row>
                <div style={{ height: '1vh' }}></div>

            </div>
            
        )
    }
}

export default ContactUsMobile;