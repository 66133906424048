import React from 'react';
import Hidden from '@material-ui/core/Hidden'
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import Services from './Services';
import ServicesMobile from './ServicesMobile';



function ServicesResponsive(props) {
    return (
        <div>
            <Hidden smDown>
                <Services/>
            </Hidden>
            <Hidden mdUp>
                <ServicesMobile/>
            </Hidden>
        </div>
    )
}

ServicesResponsive.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

export default withWidth()(ServicesResponsive);