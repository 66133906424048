import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import './TopNavigation.css';


class TopNavigationMobile extends Component {
    render() {
        return (
            <div className="container-fluid navigation-top-margin">
                <Row>
                    <Col>
                        <h1>Abasta's Cabinets</h1>
                        <p className="header-supporting-text">Handcrafted Local Designs</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>
                            <a href="/">Home</a>
                        </h4>
                    </Col>
                    <Col>
                        <h4>
                            <a href="/services">Services</a>
                        </h4>
                    </Col>
                    <Col>
                        <h4>
                            <a href="/contact">Contact</a>
                        </h4>
                    </Col>
                </Row>

            </div>
        )
    }
}

export default TopNavigationMobile;