import React, {Component} from 'react';
import { Row } from 'reactstrap';
import Carousel from "react-multi-carousel";

class PortfolioMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                desktop: {
                    breakpoint: { max: 3000, min: 1024},
                    items: 1,
                    partialVisibilityGutter: 0,
                },
                mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1,
                    partialVisibilityGutter: 0,

                },
                tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 0
                  }
            },
            listOfImages: [],
        }
    }

    componentDidMount() {
        let listOfImages = this.importAll(require.context(
            './portfolioImages', false, /\.(png|jpe?g|svg)$/));
        this.setState({listOfImages: listOfImages});
    }

    importAll(r) {
        return r.keys().map(r);
    }

    getLinkToImagesRespectiveServicesSection(image) {
        let imageName = image.default;

        if(imageName.includes("customKitchen")) {
            return "/services#customKitchens";
        } else if(imageName.includes("PorchCeiling")) {
            return "/services#customPorchCeilings";
        } else if(imageName.includes("customStairs")) {
            return "/services#customStairs";
        } else if(imageName.includes("entertainmentCenter")) {
            return "/services#entertainmentCenters";
        } else if(imageName.includes("office")) {
            return "/services#officeAndStorage";
        } else if(imageName.includes("outdoorKitchen")) {
            return "/services#outdoorKitchens";
        } else {
            return "/services"
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div style={{ height: '5vh' }}></div>
                <Row className={"justify-content-center"}>
                    <h1 className="portfolio-text-color portfolio-header-margins portfolio-text-size-mobile">Portfolio</h1>
                </Row>
                <Row className={"carousel-bottom-margin"}>
                    <div className="carousel" style={{width: '100%'}}>
                        <Carousel
                            responsive={this.state.responsive}
                            arrows
                            // autoPlay
                            // autoPlaySpeed={10000}
                            centerMode={false}
                            focusOnSelect={false}
                            slidesToSlide={1}
                            // containerClass="carousel-custom-container-class"
                            itemClass="carousel-custom-item-class"
                        >
                            {this.state.listOfImages.map((item, index) => {
                            return (
                                <a href={this.getLinkToImagesRespectiveServicesSection(item)} key={index}>
                                    <img 
                                        key={index} 
                                        src={item.default} 
                                        alt={index} 
                                        style={{width: '80%', height: '80%'}}
                                    />
                                </a>
                            );
                        })} 

                        </Carousel>
                    </div>
                </Row>
            </div>
        )
    }
}

export default PortfolioMobile;