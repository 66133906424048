import './App.css';

import HomeResponsive from './pages/home/HomeResponsive';
import ContactUsResponsive from './pages/contactUs/ContactUsResponsive';
import ServicesResponsive from './pages/services/ServicesResponsive';
import NavigationResponsive from './components/navigation/NavigationResponsive';


import {BrowserRouter, Route, Switch} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <NavigationResponsive />
        <Switch>
          <Route exact path="/">
            <HomeResponsive/>
          </Route>
          <Route exact path="/services">
            <ServicesResponsive/>
          </Route>
          <Route exact path="/contact">
            <ContactUsResponsive/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
