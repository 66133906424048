export const getInitialState = () => {
    return {
        userEmail: '',
        userEmailSubject: '',
        userEmailMessage: '',
        userPhoneNumber: '',
        showSuccessModal: false,
        showFailureModal: false,
        preferredContactMethod: '',
    }
}


export const allFieldsArePopulated = (state) => {
    return ((!state.userEmail.trim() && !state.userPhoneNumber.trim()) || !state.userEmailSubject.trim() || !state.userEmailMessage.trim())
        ? false : true;
}

let emailRegexValidator = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
export const emailIsValid = (email) => {
    return emailRegexValidator.test(email)
        ? true : false;
}

export const modalPopupSuccessTitle = () => {
    return "Success";
}

export const modalPopupEmailSuccessMessage = () => {
    return "Your email has been sent succesfully, we'll get back to you as soon as possible!"
}

export const modalPopupFailureTitle = () => {
    return "Error";
}

export const modalPopupEmailFailureMessage = () => {
    return "We're sorry, there was an error in attempting to send your email. We will work to fix this as soon as possible. In the meantime, you can get in touch with us directly through our social media accounts, or through our phone number. Sorry for the inconvenience!"
}