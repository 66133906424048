import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import './TopNavigation.css';




class TopNavigation extends Component {


    render() {
        return (
            <div className="container-fluid navigation-top-margin">
                <Row>
                    <Col className={"col-lg-4 col-md-4 align-self-center"}>
                            <h1>Abasta's Cabinets</h1>
                            <p className={"header-supporting-text"}>Handcrafted Local Designs</p>
                    </Col>
                    <Col className=""/>
                    <Col className={"right-text-align align-self-center"} md={{span: 4, offset: 2}}>
                        <Row>
                            <Col className={"nav-links-spacing"}>
                                <h4>
                                    <a href="/" className="some-color">Home</a>
                                </h4>
                            </Col>
                            <Col className={"nav-links-spacing"}>
                                <h4>
                                    <a href="/services">Services</a>
                                </h4>
                            </Col>
                            <Col className={"nav-links-spacing"}>
                                <h4>
                                    <a href="/contact">Contact</a>
                                </h4>
                            </Col>
                        </Row>
            
                    </Col>
                </Row>
            </div>
        )
    }
}

//321 width for abastas
//195 width for supporting text

export default TopNavigation;