import React, {Component} from 'react';

import ParallaxImage from '../../components/home/parallaxImage/ParallaxImage';
import AboutUs from '../../components/home/aboutUs/AboutUs';
import Portfolio from '../../components/home/portfolio/Portfolio';


import './Home.css'
class Home extends Component {


    render() {
        return (
            <div>
                <div className="parallax-image-background">
                    <div style={{
                        width: '90%',
                        margin: '0 auto',
                    }}>
                        <ParallaxImage />
                    </div>
                </div>
                <div className="about-us-background">
                    <AboutUs />
                </div>
                <div className="portfolio-image-background">
                    <Portfolio/>
                </div>
            </div>
        )
    }
}

export default Home;