import React from 'react';
import Hidden from '@material-ui/core/Hidden'
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import TopNavigation from './TopNavigation';
import TopNavigationMobile from './TopNavigationMobile';



function NavigationResponsive(props) {
    return (
        <div>
            <Hidden smDown>
                <TopNavigation/>
            </Hidden>
            <Hidden mdUp>
                <TopNavigationMobile/>
            </Hidden>
        </div>
    )
}

NavigationResponsive.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

export default withWidth()(NavigationResponsive);